import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom'

// Import Swiper React components
import {  Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import Notiflix from 'notiflix';

import SwiperCore, { EffectFlip, Navigation, Pagination } from "swiper";

Notiflix.Loading.init({
    backgroundColor: 'rgba(0,0,0,0.9)',
    svgColor: '#fff',
    clickToClose: false,
    });
    Notiflix.Confirm.init({
        titleColor: '#2C2D98',
        okButtonColor: '#ffffff',
        okButtonBackground: '#2C2D98',
    })


const Onboarding = () => {
    const navigate = useNavigate();
    Notiflix.Loading.remove();
    const [onBoardingstate , setOnboardingState] = useState();

    const pagination = document.getElementById('pagination_ct');
    const btnSlideStart = document.getElementById('start_slide');
    const [my_swiper, set_my_swiper] = useState({});

    useEffect(()=>{
        localStorage.setItem('onboarding','true');
    },[onBoardingstate])

    function handlenext() {
        navigate("/login");
        setOnboardingState(true)
    }

    function SlideStart(){
        my_swiper.slideNext();
        pagination.style.display = "flex";
        if(my_swiper.activeIndex == 3){
            pagination.style.display = "none";
        }
    }

    function skipSlide(){
        my_swiper.slideTo(3);
    }
    
    function slideChange(){
        console.log('slide change',my_swiper.activeIndex)
        if(my_swiper.activeIndex == 0 || my_swiper.activeIndex == 3){
            pagination.style.display = "none";
        }
       if(my_swiper.activeIndex == 1 || my_swiper.activeIndex == 2){
            pagination.style.display = "flex";
        }
    }
    useEffect(()=>{
        if(pagination){
            pagination.style.display = "none";
        }
        
    },[pagination, my_swiper.activeIndex]);


    return(
        <div className='block w-full'>
             <div className='"min-w-full min-h-full bg-[#2C2D98] flex items-center justify-center sm:px-5  sm:py-5 h-full sm:h-auto'>
                <div className='sm:w-full w-[100%]  sm:max-w-[30rem] mx-auto sm:rounded-3xl shadow-lg sm:min-h-[550px] min-h-[100%] h-[100%] bg-[#F5F5F5] flex flex-col justify-center relative'>
                    <div className='overflow-hidden relative'>
                            <Swiper
                            modules={[Navigation, Pagination]}
                            spaceBetween={10}
                            slidesPerView={1}
                            onInit={(ev) => {
                                set_my_swiper(ev)
                            }}
                            pagination={{ 
                                el: '.my-custom-pagination-div',
                                clickable: true,
                                renderBullet: (index, className) => {
                                 return '<span class="' + className + '">' + '' + "</span>";
                                },
                             }}
                            onSlideChange={slideChange}
                            onSwiper={(swiper) => console.log(swiper)}
                        >
                            <SwiperSlide>
                                <div className='w-full overflow-hidden text-center select-none px-5 pt-6 pb-6 text-gray-600"'>
                                    <div className=''>
                                        <img  className="mx-auto" src='/assets/images/logo_new.svg'/>
                                    </div>
                                    <div className='h-[270px]'>
                                        <img src='/assets/images/onboarding_option.png' className="w-[auto] mt-4 mx-auto h-[100%]" />
                                    </div>
                                    <h2 className="font-bold text-[24px] text-[#252525] mb-3 mt-6  max-w-[260px] sm:max-w-[100%] mx-auto block">Chat, Create and Collaborate with Artificial Intelligence</h2>
                                    <p className="text-[14px] leading-tight font-light mt-4">A revolutionary AI platform for one-on-one and group chat-based sessions with customizable AI agents.</p>
                                    <button id="start_slide" onClick={SlideStart} className='bg-[#2C2D98] w-full mt-[25px] rounded-md h-[45px] font-semibold flex justify-center items-center'>Start <img src="/assets/images/right-arrow22.png" className="w-[14px] ml-[8px] pt-[2px]" alt="" /></button>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className='w-full overflow-hidden text-center select-none px-5 pt-6 pb-6 text-gray-600"'>
                                    <h2 className="font-bold text-[24px] text-[#252525] mb-5">Key Features </h2>
                                    <div className="text-sm leading-tight text-left text-[#252525]">
                                        <ul className="flex items-stretch text-center flex-wrap justify-between font-light">
                                            <li className="max-w-[48%] bg-[#fff] p-3 mb-3 text-[#252525] font-semibold rounded-lg">
                                                <div className='text-center mb-2'>
                                                    <img className='mx-auto' src='/assets/images/key-feture-ic1.png' width={30} height={20}/>
                                                </div>
                                                One on one or group brainstorms with your own GPT based customised AI bot
                                            </li>
                                            <li className="max-w-[48%] bg-[#fff] p-3 mb-3 text-[#252525] font-semibold rounded-lg">
                                            <div className='text-center mb-2'>
                                                    <img className='mx-auto' src='/assets/images/key-feture-ic2.png' width={30} height={20}/>
                                                </div>
                                                Ability to upload PDF documents to train and enhance agents on the fly
                                            </li>
                                            <li className="max-w-[48%] bg-[#fff] p-3 mb-3 text-[#252525] font-semibold rounded-lg">
                                            <div className='text-center mb-2'>
                                                    <img className='mx-auto' src='/assets/images/key-feture-ic3.png' width={30} height={20}/>
                                                </div>
                                                Expert insights and guidance to ensure a rich brainstorming session (coming soon)
                                            </li>
                                            <li className="max-w-[48%] bg-[#fff] p-3 mb-3 text-[#252525] font-semibold rounded-lg">
                                            <div className='text-center mb-2'>
                                                    <img className='mx-auto' src='/assets/images/key-feture-ic4.png' width={30} height={20}/>
                                                </div>
                                                Wide range of pre-defined agents tailored for specific use cases.
                                            </li>
                                            <li className="max-w-[48%] bg-[#fff] p-3 mb-3 text-[#252525] font-semibold rounded-lg">
                                            <div className='text-center mb-2'>
                                                    <img className='mx-auto' src='/assets/images/key-feture-ic5.png' width={30} height={20}/>
                                                </div>
                                             Reusable and shareable agents that can be used by other users
                                            </li>
                                        </ul>
                                    
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className='w-full overflow-hidden text-center select-none px-5 pt-6 pb-6 text-gray-600"'>
                                <h2 className="font-bold text-[20px] sm:text-[24px] text-[#252525] mb-3">Responsibilities & Expectations for Testers </h2>
                                    <div className="text-sm leading-tight text-left px-2 py-2 bg-[#fff] mt-5">
                                        <ul className="list-item font-light mt-0">
                                            <li className=' border-b py-5 flex jutify-center items-center font-semibold text-[14px]'>
                                               <span className='text-[28px] font-bold text-[#2C2D98] mr-2'>1.</span> Actively engage with the platform and test its features and use cases.
                                            </li>
                                            <li className=' border-b py-5 flex jutify-center items-center font-semibold text-[14px]'>
                                                <span className='text-[28px] font-bold text-[#2C2D98] mr-2'>2.</span>Report any bugs, errors, or inconsistencies encountered during testing.
                                            </li>
                                            <li className=' border-b py-5 flex jutify-center items-center font-semibold text-[14px]'>
                                                <span className='text-[28px] font-bold text-[#2C2D98] mr-2'>3.</span> Provide constructive feedback on the functionality, usability, and overall experience.
                                            </li>
                                            <li className=' border-b py-5 flex jutify-center items-center font-semibold text-[14px]'>
                                                <span className='text-[28px] font-bold text-[#2C2D98] mr-2'>4.</span> Collaborate with our team to help improve and refine the platform's features.
                                            </li>
                                            <li className=' py-5 flex jutify-center items-center font-semibold text-[14px]'>
                                                <span className='text-[28px] font-bold text-[#2C2D98] mr-2'>5.</span> Maintain open communication with our team and actively participate in discussions.
                                            </li>
                                        </ul>
                                    
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className='w-full overflow-hidden text-center select-none px-5 pt-6 pb-6 text-gray-600"'>
                                <h2 className="font-bold text-[20px] sm:text-[24px] text-[#252525] mb-3">Join Our Testing Program </h2>
                                <p className='text-sm leading-tight text-justify mb-4'>By joining our testing program, you will help us shape the future of AI-enhanced brainstorming and ensure that Brainstormer becomes the go-to platform for creative problem-solving and idea generation. You expressly agree to the following by continuing to use this platform:</p>
                                    <div className="img-ct mx-auto">
                                        <img className="mx-auto" src="/assets/images/icon11.png" width={40} height={40} alt="" />
                                    </div>
                                    <div className="text-sm leading-tight text-left mt-4">
                                        <ul className="list-item">
                                            <li className="bg-[#fff] border border-[#B0B0B0] px-3 py-4 mb-3">
                                            Your data may be used and accessed by our product team for making improvements and monitoring behavior
                                            </li>
                                            <li className="bg-[#fff] border border-[#B0B0B0] px-3 py-4 mb-3">
                                            As this is a very early release your conversations, discussions and data uploaded to this platform may disappear as part of our on going changes
                                            </li>
                                            <li className="bg-[#fff] border border-[#B0B0B0] px-3 py-4 mb-3">
                                            You will not hold the platform or us responsible for the information you may receive. We do not guarantee accuracy of information at this time
                                            </li>
                                        
                                        </ul>
                                        
                                        <div onClick={handlenext} className="group relative flex w-full justify-center rounded-md bg-[#2C2D98] py-2 px-3 text-md font-semibold text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#2C2D98] h-[45px] flex justify-center items-center mt-[25px] cursor-pointer">Get Started <img src="/assets/images/right-arrow22.png" className="w-[14px] ml-[8px] pt-[1px]" alt="" /></div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            
                        </Swiper>
                    </div> 
                    <div id="pagination_ct" className="bottom-ct flex justify-between items-center absolute bottom-[20px] w-full px-5 z-10">
                        <a id="skip_slide_btn" className='skip-slide text-[#2C2D98] font-bold cursor-pointer' onClick={skipSlide}>Skip</a>
                        <div className="my-custom-pagination-div text-center"/>
                        <a id="next_slide_btn" onClick={SlideStart} className='next-slide-btn bg-[#2C2D98] p-3 flex rounded-[5px]  cursor-pointer z-10'><img src="/assets/images/right-arrow22.png" className="w-[20px]" alt="" /></a>
                    </div>
                </div>
                
            </div>
        </div>
       
        
    )
}

export default Onboarding