import React from 'react'
import { json, useNavigate,BrowserRouter as Router,} from "react-router-dom";
const CategoryCard = ({data, createChannel,imagePath}) => {
const navigate = useNavigate();
const handleClick = (id) =>{
    navigate('category/'+id);
}
  
  return (
    <div className="channel-card flex items-start p-5 border rounded-md w-auto h-auto mx-0 sm:mx-2 my-2 md:my-4 flex-col relative">
    
        <div className="img-ct top-sec flex flex-row items-center">
            <div className="img-innet-ct">
                 <img className=" mb-3 rounded-full shadow-lg" src={data?.attributes?.ProfileImage?.data!=null ? imagePath+data?.attributes?.ProfileImage?.data.attributes.url : "/assets/images/app_dark_img.png"} alt="Bonnie image"/>
            </div>
            <h3 className="ml-3 text-[#2C2D98] md:text-lg font-bold text-left">{data?.attributes.Name}</h3>
        </div>
      
        <div className="content-ct mt-5 h-full flex flex-col justify-between">
            <p className='description text-left font-semibold text-sm text-gray-400'>{data?.attributes.Description}</p>
            {data?.attributes?.tags?.data.length > 0 ? 
              <div className='flex text-gray-400'>
            
                {data?.attributes?.tags?.data?.map((data) =>
                    <div className="ml-2 tagTitle inline-flex items-center font-bold leading-sm uppercase px-3 py-1 rounded-full bg-white text-gray-400 border">
                      {data?.attributes?.Name}
                  </div>
                )
                }
              
              </div>:
              null  
            }
            <div className="flex mt-4 space-x-3 md:mt-4 w-full ">
                <a onClick={()=>handleClick(data?.id)} className="start-convr-link w-full inline-flex cursor-pointer items-center justify-center px-4 py-3 text-xs font-medium text-center text-white bg-[#2C2D98] rounded-lg hover:bg-blue-800  dark:focus:ring-blue-800">Explore 
                <img className="ml-5" src="/assets/images/card-arrow.svg" alt="card-arrow-icon"/></a>
            </div>
        </div>
    </div>
  )
}

export default CategoryCard