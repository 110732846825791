import React from 'react';
import Channelcard from './Channelcard';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useOutletContext } from "react-router-dom"
import { useParams } from "react-router-dom"
import { useAuthState } from "react-firebase-hooks/auth";
import { SENDBIRD_INFO } from '../constants/constants';
import { json, useNavigate,BrowserRouter as Router,Link,} from "react-router-dom";
import { auth} from "../firebase";
import ProfileIcon from './ProfileIcon';

const Botlist = () => {
  const navigate = useNavigate();
  let params = useParams()
  const [user] = useAuthState(auth);
  const [createChannel,userNameInputValue] = useOutletContext();
  const slug=params.slug
    const [existingOpt, setExistingOpt] = useState([]);
    const [botcategory, setBotCategory] = useState([]);
    // const [active, setActive] = useState(0);
    const HandleBackBtnNavigate =() => {
      navigate('/');
    }
    useEffect(()=>{
      if(user){
        getCategoryAllBot();

      }
    },[user]);
    

    const getCategoryAllBot = async() =>{
      // setActive(0);
      const usertoken = await user.getIdToken()
      axios({
        url:`${SENDBIRD_INFO.newBotUrl}/get_bots/`+slug,
        method: 'get',
        headers: {'Authorization': 'Bearer '+usertoken}
      })
      .then((res) => res)
      .then((response) => {
          setBotCategory(response.data.data[0]['attributes']);
          setExistingOpt(response.data.data[0]['attributes']['bots']['data']);
      })
      .catch((err) => {
          console.log(err.message+"file-error");
      }); 
    }
  return (
    <div className='lg:mx-5 bot-lib'>
      <div className="mb-logo-section">
        <img onClick={HandleBackBtnNavigate} className="" src="/assets/images/left-arrow-white.svg" alt="brainstromapp"/>
        <h1 className='mb-heading sm:text-xl text-[#ffffff] font-bold px-5 my-3'>{botcategory.Name}</h1>
       {userNameInputValue ? <ProfileIcon userid={userNameInputValue.uid} username={userNameInputValue.displayName}/> : null} 
        </div>
      <div className='hidden sm:flex  pt-5 pb-1 '>
      <Link to='/' className=''><img src="/assets/images/leftArrow.svg" alt="left-arrow-icon" onClick={HandleBackBtnNavigate}/></Link>
        <h2 className='text-2xl font-bold px-2 text-[#2C2D98] flex items-center'>
          {botcategory.Name}
        </h2>
      </div>
      
      <div className="wrapper sm:mx-2 mt-2 sm:mt-0 pt-2 sm:pt-0">
          <p className='text-left text-base sm:px-2 px-[20px] pt-3 pb-2 text-gray-400 '>{botcategory.Description}</p>
          <div className="bot-content-ct flex flex-wrap w-full bg-white rounded-lg justify-start mt-2 pb-14">
                {existingOpt.map((item,i) =>
                  <Channelcard data={item} key={i} createChannel={createChannel} imagePath={SENDBIRD_INFO.studioUrl} />  
                )}
          </div>
        </div>
    </div>
  )
}

export default Botlist