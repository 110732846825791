import React, {useState} from 'react';
import { signInWithEmailAndPassword,signOut   } from 'firebase/auth';
import { auth,signInWithGoogle } from '../firebase';
import { NavLink, useNavigate } from 'react-router-dom'

const Logout = () => {
    const navigate = useNavigate();
    signOut(auth).then(() => {
        // Sign-out successful.
            navigate("/login");
            console.log("Signed out successfully")
            localStorage.removeItem('appPopup');
        }).catch((error) => {
        // An error happened.
        });
 
    return(
        <>
        <h4>Loging out</h4>
        </>
    )
}
 
export default Logout