// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
    GoogleAuthProvider,
    getAuth,
    signInWithPopup,
    OAuthProvider,
    deleteUser,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signOut,
  } from "firebase/auth";
  import {
    getFirestore,
    query,
    getDocs,
    collection,
    where,
    addDoc,
  } from "firebase/firestore";
  import { NavLink, useNavigate } from 'react-router-dom'
import Notiflix from "notiflix";
import { SENDBIRD_INFO } from "./constants/constants";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyDZyvij1awV4oA2u-xndJVI0rzJEJqwS88",
//   authDomain: "brainstormerbot.firebaseapp.com",
//   projectId: "brainstormerbot",
//   storageBucket: "brainstormerbot.appspot.com",
//   messagingSenderId: "92699587769",
//   appId: "1:92699587769:web:093519d1f06d9535115768"
// };
const firebaseConfig = {
  apiKey: "AIzaSyCI36HX1rZ92BMDqU7_DubXaDYZcbrqBM0",
  authDomain: "brainstormer-dev.firebaseapp.com",
  projectId: "brainstormer-dev",
  storageBucket: "brainstormer-dev.appspot.com",
  messagingSenderId: "151421045704",
  appId: "1:151421045704:web:ba702e2f4fa5a05f2f4efd",
  measurementId: "G-DXXWSCBLYD"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

export const auth = getAuth(app);
export default app;

const googleProvider = new GoogleAuthProvider();
export const signInWithGoogle = async () => {
    try {
      Notiflix.Loading.standard();
      const res = await signInWithPopup(auth, googleProvider);
      const user = res.user;
      const q = query(collection(db, "Users"), where("email", "==", user.email));
      const docs = await getDocs(q);
      const address = user.email.split('@').pop()
      if (docs.docs.length === 0 && !SENDBIRD_INFO.allowedDomains.includes(address)) {
        await addDoc(collection(db, "PreWaitlist"), {
            email: user.email,
          });
        const userauth = auth.currentUser;         
        deleteUser(userauth).then(() => {
          // User deleted.
          window.location = "/login?waitlist=true"
          return true;
        }).catch((error) => {
            console.log(error);
        });
      }else{
        window.location = '/';
        return true;
      }
  } catch (err) {
    console.error(err);
    //alert(err.message);
  }
};
export const signInWithMicrosoft = async () => {
  try {
    const provider = new OAuthProvider('microsoft.com');
    const res = await signInWithPopup(auth, provider);
    const user = res.user;
    const q = query(collection(db, "Users"), where("email", "==", user.email));
    const docs = await getDocs(q);
    const address = user.email.split('@').pop()
    if (docs.docs.length === 0 && !SENDBIRD_INFO.allowedDomains.includes(address)) {
      await addDoc(collection(db, "PreWaitlist"), {
          email: user.email,
        });
      const userauth = auth.currentUser;         
      deleteUser(userauth).then(() => {
        // User deleted.
        window.location = "/login?waitlist=true"
        return true;
      }).catch((error) => {
          console.log(error);
      });
    }else{
      window.location = '/';
      return true;
    }
} catch (err) {
  console.error(err);
  //alert(err.message);
}
};
  