import React from 'react';
import { useState, useEffect } from 'react';
import { getMessaging, getToken } from 'firebase/messaging';
import SendBird from 'sendbird';
import { SENDBIRD_INFO } from '../constants/constants';

const ProfileIcon = ({userid,username}) => {

    const [userOpen, setUserOpen] = useState(false);
    const [navOpen, setNavOpen] = useState(false);


    const onNotification =() => {   
        const messaging = getMessaging();

        const appId = SENDBIRD_INFO.appId;
        const vapidKey = 'BCwK7AipsX-mZXOTYr0KcLFNSidXqEioJdtqmqrKFtMl1SPpqCwGTYYWEb3o9JlH4_2WwoQMf0DNlxqpqTelxUQ';
        console.log('hi');
        const sbb = new SendBird({appId});
        console.log(userid);
        sbb.connect(userid, username, (user, error) => {

        Notification.requestPermission().then(permission => {

            if (permission === 'granted') {

                getToken(messaging, {vapidKey: vapidKey})
                .then(currentToken => {
                if (currentToken) {
                    sbb.registerGCMPushTokenForCurrentUser(currentToken, (response, error) => {
                    if(error) console.log(error);
                    console.log("Token Registered:", currentToken)
                    });
                }
                })
                .catch(err => {
                console.log('An error occurred while retrieving a token. ', err);
                });
            } else {
                getToken(messaging, {vapidKey: vapidKey})
                .then(currentToken => {
                if (currentToken) {
                    sbb.registerGCMPushTokenForCurrentUser(currentToken, (response, error) => {
                    if(error) console.log(error);
                    console.log("Token Registered:", currentToken)
                    setUserOpen(!userOpen)
                    navOpen && setNavOpen(!navOpen)
                    });
                }
                })
                .catch(err => {
                console.log('An error occurred while retrieving a token. ', err);
                });
            console.log('Unable to get permission to notify.');
            }
        })
        })
    };

return(
    <div className='relative pt-1'>
        <div className="text-md text-white justify-end px-0 sm:px-3 sm:px-5 cursor-pointer flex align-items-center"  onClick={() => {
                setUserOpen(!userOpen)
                navOpen && setNavOpen(!navOpen)
        }}>
            <img src="/assets/images/userIcon.svg" alt="user-icon" />            
            <img src="/assets/images/dropdownIcon.svg" alt="dropdown-icon" className='hidden sm:block px-1 w-[80%]' />
        </div>
        <div className={`absolute top-8 right-6 w-[200px] md:right-6  mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black z-20 ring-opacity-5 ${userOpen ? 'transition duration-500 ease-in-out transform opacity-100 scale-y-100' : 'transition duration-500 ease-in-out transform opacity-0 scale-y-0'}`}>
            <div className="py-1 " role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                <span className="block px-2 py-2 text-md text-black-700" role="menuitem">
                    <span className="flex flex-col">
                        <a className='hover:text-[#2C2D98] cursor-pointer'>
                            <span className='py-2 px-1'>Hi! {username}</span>
                        </a>
                        <a className="hover:text-[#2C2D98]" href="/logout">
                            <span className='py-2 px-1'>Logout</span>
                        </a>
                        {
                            "Notification" in window ? (Notification.permission !== "granted") ?  
                            <a onClick={onNotification} className="hover:text-[#2C2D98] cursor-pointer">
                                <span className='py-2 px-1 '>Allow Notification</span>
                            </a>:null
                                    : null
                        }
                    </span>
                </span>
            </div>
            <div className="flex hidden">
                <button className="text-gray-800 dark:text-gray-800 hover:text-gray-800 inline-flex items-center justify-center p-3 rounded-md focus:outline-none">
                    <svg width="20" height="20" fill="currentColor" className="w-6 h-6" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1664 1344v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45z"></path></svg>
                </button>
            </div>
        </div>
    </div>
)}

export default ProfileIcon