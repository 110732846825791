import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Logout from './auth/logout';
import Login from './auth/login';
import Signup from './auth/signup';
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom';
import Onboarding from './components/Onboarding';

import MainWindow from './components/MainWindow';
import Botlist from './components/Botlist';
import Categorylist from './components/Categorylist';

const App = () => {
  const navigate = useNavigate();
	const location = useLocation();
  return (
    <div className="flex">
      <Routes>
        <Route path='/' element={<MainWindow />}>
            <Route exact path='/:id' element={<Categorylist/>} />
            <Route path='/category/:slug' element={<Botlist />} />
        </Route>
        <Route path='/login' element={<Login />} />
        <Route path='/signup' element={<Signup />} />
        <Route path='/logout' element={<Logout />} />
        <Route path='/onboarding' element={<Onboarding/>}/>
      </Routes>
    </div>
  );
}

export default App;
