import { useState, useEffect,useCallback, useRef} from 'react';
import SendbirdChat from '@sendbird/chat';
import { MentionsInput, Mention } from 'react-mentions';
import {
    GroupChannelModule,
    GroupChannelFilter,
    GroupChannelListOrder,
    MessageFilter,
    MessageCollectionInitPolicy
} from '@sendbird/chat/groupChannel';
import Notiflix from 'notiflix';
import { SENDBIRD_INFO } from '../constants/constants';
import { timestampToTime, handleEnterPress } from '../utils/messageUtils';
import { useAuthState } from "react-firebase-hooks/auth";
import { collection, getDocs, doc, getDoc, updateDoc} from "firebase/firestore";
import { auth, db } from "../firebase";
import { json, useNavigate,BrowserRouter as Router,
    Link,
    NavLink,
    useParams} from "react-router-dom";
import axios from "axios";
import shortid from "shortid";
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import ReactMarkdown from 'react-markdown';
// import remarkParse from 'remark-parse'
import gfm from 'remark-gfm'
import rehypeRaw from "rehype-raw";
import remarkMath from 'remark-math';
import remarkRehype from 'remark-rehype';
import PopupShow from './PopupShow';
import Categorylist from './Categorylist';
import { Outlet} from "react-router-dom"
import ProfileIcon from './ProfileIcon';
import debounce from 'lodash.debounce';


// import rehypeKatex from 'rehype-katex'
const searchParams = new URLSearchParams(document.location.search)

let sb;
Notiflix.Loading.init({
    backgroundColor: 'rgba(0,0,0,0.9)',
    svgColor: '#fff',
    clickToClose: false,
});
Notiflix.Confirm.init({
    titleColor: '#2C2D98',
    okButtonColor: '#ffffff',
    okButtonBackground: '#2C2D98',
});

Notiflix.Report.init({
    svgSize: '55px',
});

const MainWindow = (props) => {
    //  debounced callback function
    const debouncedOnChange = useCallback(
        debounce(function(){
            loadChannels(channelHandlers).then(function(channels,error) {
                updateState({...stateRef.current, channels: channels[0] });
            });
        }, 1500)
      , []);

    let { id } = useParams();
    const CHdata = searchParams.get('chId');

    let { slug } = useParams();
    
    const [searchUrl,setSearchUrl] = useState(id)
    useEffect(()=>{
        setSearchUrl(id)
    },[id])


    
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();

    const onClick = () => {
        updateState({...state, showpop:!state.showpop,userSucessMsg:false});
    }

    const closeCreateStormPopup = () =>{
        updateState({...state, showCreatePopup:!state.showCreatePopup,createStormSuccessMsg:false});
    }
    const [newJoin, setNewJoin] = useState(null)
    const [deleteState,setDeleteState] = useState(true)
    useEffect(()=>{

    },[newJoin])

    
    
    const [ppState,setPpstate] = useState(false);
    const [deviceType, setDeviceType] = useState('');
    const [browserType, setBrowserType] = useState('');
   
    useEffect(()=>{
        if((("standalone" in window.navigator) && !window.navigator.standalone) || ( !window.matchMedia('(display-mode: standalone)').matches)){
            setPpstate(true)
        }
        const userAgent = navigator.userAgent;
        if (/Android/i.test(userAgent)) {
            setDeviceType('Android');
        } 
        else if (/iPhone|iPad|iPod/i.test(userAgent)) {
            setDeviceType('IOS');
            const userAgent = navigator.userAgent.toLowerCase();
            if (userAgent.indexOf('chrome') > -1) {
                setBrowserType('ios-chrome');
            }  else if (userAgent.indexOf('safari') > -1) {
                setBrowserType('ios-safari');
            } 
        } else if (/Windows Phone/i.test(userAgent)) {
            setDeviceType('Windows Phone');
        } else {
            setDeviceType('Other');
        }
    },[])

    const [state, updateState] = useState({
        applicationUsers: [],
        groupChannelMembers: [],
        currentlyJoinedChannel: null,
        messages: [],
        channels: [],
        messageInputValue: "",
        userNameInputValue: "",
        userIdInputValue: "",
        channelNameUpdateValue: "",
        settingUpUser: false,
        file: null,
        messageToUpdate: null,
        messageCollection: null,
        loading: true,
        error: false,
        channelName:"",
        showpop:true,
        userSucessMsg:false,
        sidebarSlider:false,
        metaCollection:[],
        showCreatePopup:true,
        createStormSuccessMsg:false,
        currentlyUpdatingChannel:null,
        channeldata:null,
        newlyJoinChannelUrl:null,
        operators: [],
        delteMsg:false,
        suggestion:[],
    });

    const [sortBychnList,setsortBychnList] = useState(false);


    useEffect(()=>{
        (async () => {
            if(sortBychnList == true){
                const groupChannelFilter = new GroupChannelFilter();
                groupChannelFilter.includeEmpty = true;

            const collection = sb.groupChannel.createGroupChannelCollection({
                filter: groupChannelFilter,
                order: GroupChannelListOrder.LATEST_LAST_MESSAGE,
            });

            const channels = await collection.loadMore();
            updateState({ ...stateRef.current, channels: channels });
            }
        
         })
        ();

        return () => {
            setsortBychnList(false)
            // this now gets called when the component unmounts
        };
    },[sortBychnList])

    useEffect(() => {
        const onboarding = localStorage.getItem('onboarding');
        if (loading) return;
        if(!onboarding && !user) return navigate("/onboarding");
        if (!user) return navigate("/login");
        user.getIdToken().then(function(idToken) {  // <------ Check this line
            // console.log(idToken); // It shows the Firebase token now
            
        });
        updateState({ ...state, userIdInputValue:user.uid,userNameInputValue:user.displayName });
         (async () => {
            const sendbirdChat = await SendbirdChat.init({
                appId: SENDBIRD_INFO.appId,
                localCacheEnabled: true,
                modules: [new GroupChannelModule()]
            });
    
            await sendbirdChat.connect(user.uid);
            await sendbirdChat.setChannelInvitationPreference(true);
    
            const userUpdateParams = {};
            userUpdateParams.nickname = user.displayName;
            userUpdateParams.userId = user.uid;
            await sendbirdChat.updateCurrentUserInfo(userUpdateParams);
    
            sb = sendbirdChat;
            updateState({ ...state, loading: true });
            const [channels, error] = await loadChannels(channelHandlers);
            if (error) {
                return onError(error);
            }
    
            updateState({ ...state, channels: channels, loading: false, settingUpUser: false });
        })();

        return () => {

            // this now gets called when the component unmounts
        };
    
    }, [user, loading]);
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
      const handlePopState = () => {
        setRefresh(true);
      };
      window.addEventListener('popstate', handlePopState);
  
      return () => {
        window.removeEventListener('popstate', handlePopState);
      };
    }, []);
  
    useEffect(() => {
      // Code to refresh the component or trigger necessary actions
      if (refresh) {
        // Perform the refresh logic here
        window.location.reload();
        setRefresh(false); // Reset the refresh state after refresh
      }
    }, [refresh]);
    useEffect(() => {
        const handleMessage = (event) => {
          if (event.data && event.data.type === 'navigate') {
            // Handle the navigation based on the received URL
            window.location.href = event.data.url;
          }
        };
    
        // Create a Broadcast Channel with the same name used in the service worker
        const channel = new BroadcastChannel('navigation');
        channel.addEventListener('message', handleMessage);
    
        return () => {
          channel.removeEventListener('message', handleMessage);
          channel.close();
        };
      }, []);
      
    useEffect (()=>{
        if(newJoin){
            const data = newJoin;
            setNewJoin(null);
            navigate(`/${newJoin.replace("sendbird_group_channel_","")}`);
        }
    },[state.channels])

     
    useEffect(()=>{
        (async () => {
            if(searchUrl){
                var search_trimmed = "sendbird_group_channel_"+searchUrl
                 if (state.messageCollection && state.messageCollection.dispose) {
                     state.messageCollection?.dispose();
                 }
     
                 if (state.currentlyJoinedChannel?.url === search_trimmed) {
                     return null;
                 }
                 const { channels } = state;
                 updateState({ ...state, loading: true });
                 var channel = channels.find((channel) => channel.url === search_trimmed);
                 await channel?.markAsRead(); 
                 if(document.getElementById("storm_msg")){
                    document.getElementById("storm_msg").focus();
                 }
                 
             const onCacheResult = (err, messages) => {
                 updateState({ ...stateRef.current, currentlyJoinedChannel: channel, messages: messages.reverse(), loading: false })
     
             }
     
             const onApiResult = (err, messages) => {
                 updateState({ ...stateRef.current, currentlyJoinedChannel: channel, messages: messages.reverse(), loading: false })
             }
                if(channel){
                    document.querySelector('.channel').style.zIndex = '3';
                    const collection = loadMessages(channel, messageHandlers, onCacheResult, onApiResult);
                    updateState({ ...state,messageCollection:collection });
                    setSearchUrl(null)
                }
             }
        })();

        return () => {

            // this now gets called when the component unmounts
        };
        
    
       
    },[state.channels.length !=0 && searchUrl])
    //need to access state in message received callback
    const stateRef = useRef(null);
    stateRef.current = state;

    const channelRef = useRef(null);

    const channelHandlers = {
        onChannelsAdded: (context, channels) => {
            const updatedChannels = [...channels, ...stateRef.current.channels];
            
            updateState({ ...stateRef.current, channels: updatedChannels, applicationUsers: [] });
            
        },
        onChannelsDeleted: (context, channels) => {
            const updatedChannels = stateRef.current.channels.filter((channel) => {
                return !channels.includes(channel.url);
            });
            updateState({ ...stateRef.current, channels: updatedChannels });

        },
        onChannelsUpdated: (context, channels) => {
            const updatedChannels = stateRef.current.channels.map((channel) => {
                const updatedChannel = channels.find(incomingChannel => incomingChannel.url === channel.url);
                if (updatedChannel) {
                    return updatedChannel;
                } else {
                    return channel;
                }
            });

            updateState({ ...stateRef.current, channels: updatedChannels });
            
            if(context.source == 'EVENT_MESSAGE_RECEIVED'){
               setTimeout(()=>{
                setsortBychnList(true)
               },2000)
               // debouncedOnChange();
               
            }
        },
    }
    
    const messageHandlers = {
        onMessagesAdded: (context, channel, messages) => {
            channel.markAsRead();
            const updatedMessages = [...stateRef.current.messages, ...messages];

            updateState({ ...stateRef.current, messages: updatedMessages });

        },
        onMessagesUpdated: (context, channel, messages) => {
            const updatedMessages = [...stateRef.current.messages];
            for (let i in messages) {
                const incomingMessage = messages[i];
                const indexOfExisting = stateRef.current.messages.findIndex(message => {
                    return incomingMessage.messageId === message.messageId;
                });

                if (indexOfExisting !== -1) {
                    updatedMessages[indexOfExisting] = incomingMessage;
                }else if(indexOfExisting == -1 && context.source != 'EVENT_MESSAGE_SENT_SUCCESS'){
                    updatedMessages.push(incomingMessage);
                }

                const indexOfExistingFile = stateRef.current.messages.findIndex(message => {
                    return incomingMessage.reqId === message.reqId;
                });

                if (indexOfExistingFile !== -1 && context.source == 'EVENT_MESSAGE_SENT_SUCCESS') {
                    updatedMessages[indexOfExistingFile] = incomingMessage;
                }
            }
            
            
            updateState({ ...stateRef.current, messages: updatedMessages });
            setDeleteState(true);
           
        },
        onMessagesDeleted: (context, channel, messageIds) => {
            const updateMessages = stateRef.current.messages.filter((message) => {
                return !messageIds.includes(message.messageId);
            });
            updateState({ ...stateRef.current, messages: updateMessages});
           

        },
        onChannelUpdated: (context, channel) => {
           
           
        },
        onChannelDeleted: (context, channelUrl) => {
        },
        onHugeGapDetected: () => {
        }
    }

    const scrollToBottom = (item, smooth) => {
        item?.scrollTo({
            top: item.scrollHeight+10,
            behavior: smooth,
        })
    }

    useEffect(() => {
        scrollToBottom(channelRef.current)
        window?.scrollTo({top:document.querySelector('.message-list')?.scrollHeight+10});
    }, [state.currentlyJoinedChannel])

    useEffect(() => {
        
        if(deleteState && channelRef){
            scrollToBottom(channelRef.current, 'smooth')
            window?.scrollTo({top:document.querySelector('.message-list')?.scrollHeight+10});
        }
    }, [state.messages])

    const onError = (error) => {
        updateState({ ...state, error: error.message });
        console.log(error);
    }

    const handleJoinChannel = async (channelUrl) => {
        document.querySelector('.channel').style.zIndex = '3';
        if (state.messageCollection && state.messageCollection.dispose) {
            state.messageCollection?.dispose();
        }

        if (state.currentlyJoinedChannel?.url === channelUrl) {
            return null;
        }
        const { channels } = state;
        updateState({ ...state, loading: true });
        var channel = channels.find((channel) => channel.url === channelUrl);
        
        await channel.markAsRead();
        const onCacheResult = (err, messages) => {
            updateState({ ...stateRef.current, currentlyJoinedChannel: channel, messages: messages.reverse(), loading: false })

        }

        const onApiResult = (err, messages) => {
            updateState({ ...stateRef.current, currentlyJoinedChannel: channel, messages: messages.reverse(), loading: false })
        }
    const operators = await handleLoadOperators(channel)

        const collection = loadMessages(channel, messageHandlers, onCacheResult, onApiResult);
        const keys = ['prompt'];
        const metadata= [];
        updateState({ ...state, messageCollection: collection,metaCollection:metadata,operators: operators });

    }

    const handleLeaveChannel = async () => {
        const { currentlyJoinedChannel } = state;
        await currentlyJoinedChannel.leave();
        navigate("/")
       
        updateState({ ...state, currentlyJoinedChannel: null,sidebarSlider:!state.sidebarSlider })
        document.querySelector('.channel').style.zIndex = '1';
    }

    const channelDataUpdate = async(chaneData) =>{
        updateState({...state, channeldata:chaneData});
    }

    const HandleBackBtn = async () =>{
        updateState({...state, currentlyJoinedChannel:null});
        setSearchUrl(null)
        navigate("/");
        document.querySelector('.channel').style.zIndex = '1';
    }

    const handleUpdateChannel = async () => {

        const { currentlyUpdatingChannel, channeldata, channels } = state;
        const [updatedChannel, error] = await updateChannel(currentlyUpdatingChannel, channeldata);
        if (error) {
            return onError(error);
        }
        const indexToReplace = channels.findIndex((channel) => channel.url === currentlyUpdatingChannel.channelUrl);
        const updatedChannels = [...channels];
        updatedChannels[indexToReplace] = updatedChannel;
        updateState({ ...state, channels: updatedChannels, currentlyJoinedChannel:updatedChannel  });
    }

    const handleCreateChannel = async (channelName,channelDiscription,dataPdffile,handleCreateStormPopup) => {
        const [groupChannel,groupChannelMeta, error] = await createChannel(channelName, ['Brainstorm'],channelDiscription,dataPdffile);
        setNewJoin(groupChannel.url)
        if (error) {
            return onError(error);
        }
    }

    const handleCreateChannelBot = async (channelName,botId,chanelCr,dataPdffile,channelImg) => {
        const [groupChannel,groupChannelMeta, error] = await createChannel(channelName, [botId],"","",channelImg);
        setNewJoin(groupChannel?.url)
        if (error) {
            return onError(error);
        }

    }

    const handleUpdateChannelMembersList = async () => {
        const { currentlyJoinedChannel, groupChannelMembers } = state;
        await inviteUsersToChannel(currentlyJoinedChannel, groupChannelMembers);
        updateState({ ...state, applicationUsers: [] });
    }

    const handleDeleteChannel = async (channelUrl) => {
        const [channel, error] = await deleteChannel(channelUrl);
        if (error) {
            return onError(error);
        }
        updateState({ ...state, currentlyJoinedChannel:null });
    }

    const handleMemberInvite = async () => {
        const [users, error] = await getAllApplicationUsers();
        if (error) {
            return onError(error);
        }
        updateState({ ...state, applicationUsers: users });
    }

    const onUserNameInputChange = (e) => {
        const userNameInputValue = e.currentTarget.value;
        updateState({ ...state, userNameInputValue });
    }

    const onUserIdInputChange = (e) => {
        const userIdInputValue = e.currentTarget.value;
        updateState({ ...state, userIdInputValue });
    }

    const onMessageInputChange = (e) => {
        const messageInputValue = e.target.value;
        let membername = [];
        let suggestion = [];
        if(messageInputValue.length > 0 ){
            state.currentlyJoinedChannel.members.map((item)=>{
                if(item.nickname == "Brainstorm"){
                    membername.push({id:"Storm",display:"Storm"});
                }
                else if(item.nickname == user?.displayName){
                    membername = membername;
                }
                else {
                    membername.push({id:item.nickname,display:item.nickname});
                }
            });
            
            suggestion = membername;
        }
        updateState({ ...state, messageInputValue:messageInputValue, suggestion:suggestion });
    }
    

    const onHandleSuggestions = (e) => {
        const memberTextPos = state.messageInputValue.search(/@/);
        const memberVal = state.messageInputValue.substring(memberTextPos);
        updateState({ ...state, messageInputValue:state.messageInputValue.replace(memberVal,'')+"@"+e.target.innerText+" "});
    }
    
    const sendMessage = async () => {
        if (state.messageInputValue.trim().length) {
            const { messageToUpdate, currentlyJoinedChannel, messages } = state;
            if (messageToUpdate) {
                const userMessageUpdateParams = {};
                userMessageUpdateParams.message = state.messageInputValue
                const updatedMessage = await currentlyJoinedChannel.updateUserMessage(messageToUpdate.messageId, userMessageUpdateParams)
                const messageIndex = messages.findIndex((item => item.messageId == messageToUpdate.messageId));
                messages[messageIndex] = updatedMessage;
                setDeleteState(false)
                updateState({ ...state, messages: messages, messageInputValue: "", messageToUpdate: null });
            } else {
                const userMessageParams = {};
                userMessageParams.message = state.messageInputValue
                currentlyJoinedChannel.sendUserMessage(userMessageParams)
                    .onSucceeded((message) => {
                        setDeleteState(true)
                        updateState({ ...stateRef.current, messageInputValue: "" });
                    })
                    .onFailed((error) => {
                        console.log(error)
                    });
            }
        }
        updateState({...state,messageInputValue:'',messageToUpdate: null})
    }

    const onFileInputChange = async (e) => {
        if (e.currentTarget.files && e.currentTarget.files.length > 0) {
            const { currentlyJoinedChannel, messages } = state;

            let fileMessageParams = {};
            fileMessageParams.file = e.currentTarget.files[0];

            let extension = fileMessageParams.file.name.split('.').pop().toLowerCase();
            if(extension =='pdf'){
                currentlyJoinedChannel.sendFileMessage(fileMessageParams)
                .onSucceeded((message) => {
                    updateState({ ...stateRef.current, messageInputValue: "", file: null });
                })
                .onFailed((error) => {
                    console.log(error)
                });
              }else{
                Notiflix.Report.warning(
                    'Please upload pdf file',
                    "",
                    "okay",
                );
              }
              e.target.value = null
        }


        
    }

    const handleDeleteMessage = async (messageToDelete) => {
        const { currentlyJoinedChannel } = state;
        setDeleteState(false);
        await deleteMessage(currentlyJoinedChannel, messageToDelete); // Delete
        
    }

    const updateMessage = async (message) => {
        setDeleteState(false);
        updateState({ ...state, messageToUpdate: message, messageInputValue: message.message });
    }

    const handleLoadMemberSelectionList = async () => {
        updateState({ ...state, currentlyJoinedChannel: null });
        const [users, error] = await getAllApplicationUsers();
        if (error) {
            return onError(error);
        }
        updateState({ ...state, currentlyJoinedChannel: null, showCreatePopup:!state.showCreatePopup,applicationUsers: users, createStormSuccessMsg:false, groupChannelMembers: [sb.currentUser.userId] });
        document.querySelector('.overlay').style.zIndex = '4';

    }

    const addToChannelMembersList = (userId) => {
        const groupChannelMembers = [...state.groupChannelMembers, userId];
        updateState({ ...state, groupChannelMembers: groupChannelMembers });
    }

    const setupUser = async () => {
        const { userNameInputValue, userIdInputValue } = state;
        const sendbirdChat = await SendbirdChat.init({
            appId: SENDBIRD_INFO.appId,
            localCacheEnabled: true,
            modules: [new GroupChannelModule()]
        });

        await sendbirdChat.connect(userIdInputValue);
        await sendbirdChat.setChannelInvitationPreference(true);

        const userUpdateParams = {};
        userUpdateParams.nickname = userNameInputValue;
        userUpdateParams.userId = userIdInputValue;
        await sendbirdChat.updateCurrentUserInfo(userUpdateParams);

        sb = sendbirdChat;
        updateState({ ...state, loading: true });
        const [channels, error] = await loadChannels(channelHandlers);
        if (error) {
            return onError(error);
        }

        updateState({ ...state, channels: channels, loading: false, settingUpUser: false });
    }

    const dataHandleShowList = () =>{
        updateState({...state, sidebarSlider:!state.sidebarSlider});
       
    }

    const handleSideBar = () =>{
        if(state.sidebarSlider==true){
            updateState({...state, sidebarSlider:false});
        }
    }

    const currentlyUpdatingChannelUpdate = async(currentlyUpdatingChannel) =>{
        updateState({...state, currentlyUpdatingChannel:currentlyUpdatingChannel});
    }

    const handleSendIvite =  async (uname,) => {
        document.querySelector('.overlay').style.zIndex = '1';
        Notiflix.Loading.standard();
      const data = await fetch(`${SENDBIRD_INFO.backendUrl}/user_invite`, {
            method: 'POST',
            body: JSON.stringify({
            "channel_url" : state.currentlyJoinedChannel.url,
            "invite_list" : [
                {
                    "name" : uname.userName,
                    "email" : uname.useremailId
                },
            ],
            "username" : user.displayName,
            "storm_name" : state.currentlyJoinedChannel.name
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        })
        .then((res) => res.json())
        .then((response) => {
            Notiflix.Loading.remove();
            updateState({...state, userSucessMsg:!state.userSucessMsg});
            
        })
        .catch((err) => {
            Notiflix.Loading.remove();
            console.log(err.message);
         });

        return(data)
       
    }

    const chgetAllMetaData = async()=>{
        const keys= ['prompt'];
        const data = await state.currentlyJoinedChannel.getMetaData(keys);
    }
    

    if (state.loading) {
         Notiflix.Loading.standard();
    }else{
        Notiflix.Loading.remove();
    }

    if (state.error) {
        return <div className="error">{state.error} check console for more information.</div>
    }


    return (
        <>
        <div className='chatbot-dashboard w-full mx-auto flex h-full bg-white'>

            { ppState ?
            <PopupShow deviceType={deviceType} browserType={browserType}/>
            :null}
            
            <CreateUserForm
                setupUser={setupUser}
                userNameInputValue={state.userNameInputValue}
                userIdInputValue={state.userIdInputValue}
                settingUpUser={state.settingUpUser}
                onUserIdInputChange={onUserIdInputChange}
                onUserNameInputChange={onUserNameInputChange}
            />
            <ChannelList
                channels={state.channels}
                handleJoinChannel={handleJoinChannel}
                handleCreateChannel={handleLoadMemberSelectionList}
                handleDeleteChannel={handleDeleteChannel}
                dataHandleShowListItem={dataHandleShowList}
                handleSideBar={handleSideBar}
                handleLoadMemberSelectionList={handleLoadMemberSelectionList}
                userNameInputValue={user}
                currentlyJoinedChannel={state.currentlyJoinedChannel}
            />
            <MembersSelect
                applicationUsers={state.applicationUsers}
                groupChannelMembers={state.groupChannelMembers}
                currentlyJoinedChannel={state.currentlyJoinedChannel}
                addToChannelMembersList={addToChannelMembersList}
                handleCreateChannel={handleCreateChannel}
                handleJoinChannel={handleJoinChannel}
                handleUpdateChannelMembersList={handleUpdateChannelMembersList}
                showCreatePopup={state.showCreatePopup} 
                handleCreateStormPopup={closeCreateStormPopup} 
                createStormSuccessMsg={state.createStormSuccessMsg}
                handleDeleteChannel={handleDeleteChannel}
            />
             <Channel
             currentlyJoinedChannel={state.currentlyJoinedChannel}
             handleLeaveChannel={handleLeaveChannel}
             userNameInputValue={user}
             channelRef={channelRef}
             HandleBackBtn={HandleBackBtn}
             handleCreateChannelBot={handleCreateChannelBot}
             >
             <div className="groupChatWidow">
                 <MessagesList
                     messages={state.messages}
                     currentlyJoinedChannel={state.currentlyJoinedChannel}
                     handleDeleteMessage={handleDeleteMessage}
                     updateMessage={updateMessage}
                     channelRef={channelRef}
                     dataHandleShowListItem={dataHandleShowList}
                     sidebarSlider={state.sidebarSlider}
                     HandleBackBtn={HandleBackBtn}
                 />
                 <MessageInput
                     value={state.messageInputValue}
                     onChange={onMessageInputChange}
                     sendMessage={sendMessage}
                     fileSelected={state.file}
                     onFileInputChange={onFileInputChange}
                     suggestion={state.suggestion}
                 />
             </div>   
            </Channel>

            {state.sidebarSlider?
            <MembersList
                    channel={state.currentlyJoinedChannel}
                    handleMemberInvite={handleMemberInvite}
                    handleLeaveChannel={handleLeaveChannel}
                    handlePopup={onClick}
                    handleUpdateChannel={handleUpdateChannel}
                    channelDataUpdate={channelDataUpdate}
                    currentlyUpdatingChannelUpdate={currentlyUpdatingChannelUpdate}
                    dataHandleShowListItem={dataHandleShowList}
                    sidebarSlider={state.sidebarSlider}
                    channels={state.ch}
                />
            :null}
            <InviteUserch showpopState={state.showpop} handlePopup={onClick} handleSendIvite={handleSendIvite} userSucessMsg={state.userSucessMsg} />
           
            </div>
           
        </>
    );
};

// Chat UI Components
const ChannelList = ({
    channels,
    handleJoinChannel,
    handleDeleteChannel,
    handleLoadMemberSelectionList,
    openChatList,
    dataHandleShowListItem,
    userNameInputValue,
    handleSideBar,
    currentlyJoinedChannel
}) => {
    const [navOpen, setNavOpen] = useState(false);
    const [userOpen, setUserOpen] = useState(false);
    const [dataChannel,setDataChannel] = useState();

    var checkTags = true;    

    const exploreChannel = () => {
        document.querySelector('.channel').style.zIndex = '1';
    }
 
    function isHTML(str) {
        var a = document.createElement('div');
        a.innerHTML = str;
          var c = a.childNodes
          if (c[0].nodeType == 1){
            return true; 
          }
        
        return false;
    }

    
    return (
        <div className='channel-list flex flex-col justify-between'>
            <div className='h-[90%] sm:h-full'>
                <div className="hidden md:flex items-center justify-between bg-white ">
                <img className="mx-4 my-2 w-auto p-1 brainstrom-logo" src="/assets/images/logo_new.svg" alt="brainstromapp"/>
                    <div>

                      {userNameInputValue ? <ProfileIcon userid={userNameInputValue.uid} username={userNameInputValue.displayName }/> : null }  
                        
                    </div>
                </div>

                <div className="mb-logo-section">
                    <img onClick={exploreChannel} className="" src="/assets/images/cross.svg" alt="brainstromapp"/>
                    <h1 className='mb-heading sm:text-xl text-[#ffffff] font-bold px-5 my-3'>My Storms</h1>

                    <div>
                        {userNameInputValue ? <ProfileIcon userid={userNameInputValue.uid} username={userNameInputValue.displayName }/> : null }  
                    </div>


                </div>
                
                <h2 className=' hidden md:block sm:text-xl  font-bold px-5 my-3'>My Storms</h2>
                <div className='channel-list-ct'>
                {channels.length == 0?
                <div className='px-9 flex h-full items-center'>
                    <div>
                        <h4 className='font-bold text-base pl-4 relative before:block before:absolute before:left-0 before:top-2 before:w-[8px] before:h-[8px] before:rounded-full before:bg-[#DF6E34]'>No storms yet</h4>
                        <p className='text-left font-light text-sm mb-5'>Start a new AI enabled brainstorming session by clicking the button below</p>
                        <img src='assets/images/arrow-2line.png' className='mx-auto h-[200px]' />
                    </div>
                </div>
                :null}
                {channels && channels.map(channel => {
                    return (
                        <div key={channel.url} onClick={()=>handleSideBar()}
                         className={`${currentlyJoinedChannel && channel.url == currentlyJoinedChannel.url?'active':''} channel-list-item my-2 mx-auto`}>
                            <NavLink to={`/${(channel.url).replace('sendbird_group_channel_','')}`}
                                    
                                    className="channel-list-item-name flex items-center justify-between">
                                    <div className="img-ct overflow-hidden rounded-[20px] w-[40px] [h-40px] mr-2 max-w-[40px]">
                                        <img src={channel.coverUrl} alt="" className='w-full'/>
                                    </div>
                                    <div className='content-ct w-[85%]'>
                                        <div className='flex items-center justify-between w-full mb-[5px]'>
                                            <h2 className="font-semibold text-[18px] chnamesidebar">{channel.name}</h2>
                                            {channel.unreadMessageCount ==0 ? <div className="font-medium text-xs text-gray-400">{channel.lastMessage && channel.lastMessage.updatedAt!=0 ? timestampToTime(channel.lastMessage.updatedAt):null}</div>:<div className="font-medium text-xs text-[#2C2D98]">{channel.lastMessage && channel.lastMessage.updatedAt!=0 ?timestampToTime(channel.lastMessage.updatedAt):null}</div>}
                                        </div>
                                        
                                        <div className='flex items-center justify-between'>
                                            {isHTML(channel?.lastMessage?.message) && checkTags ? <div className="last-message text-sm text-[#000] font-semibold max-w-[230px]">Typing...</div>:<div className="last-message text-sm text-[#000] font-semibold max-w-[230px]">{channel?.lastMessage?.message}</div>}
                                            {isHTML(channel?.lastMessage?.message) ? null : channel.unreadMessageCount ==0 ?null:<div className="font-medium text-xs ml-2 bg-[#2C2D98] px-[8px] py-[0px] rounded-xl text-[#fff] flex justify-center items-center leading-[16px] text-[11px]">{channel.unreadMessageCount}</div>}
                                        </div>
                        
                                        
                                    </div>
                                
                            </NavLink>
                            <div>
                            
                             
                            </div>
                        </div>
                    );
                })}
            </div>
            </div>
             <div className="channel-type sm:block flex justify-between">
                <button className="create-btn group sm:mr-0 mr-3 flex justify-center rounded-md bg-[#2C2D98] py-2 px-3 text-md font-semibold text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 bottom-4 left-4 w-full right-4 items-center" onClick={() => handleLoadMemberSelectionList()}>
                    Create <img src="/assets/images/create.svg" className="ml-2" alt="explore-icon" />
                </button>
                <button className="explore-btn group sm:hidden flex justify-center rounded-md bg-grey-500 py-2 px-3 text-md font-semibold text-[#fff] hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 bottom-4 left-4 w-full right-4 items-center" onClick={exploreChannel}>
                    Explore <img src="/assets/images/Discovery.svg" alt="explore-icon" />   
                </button>
            </div>
        </div >);
}

const ChannelName = ({ members }) => {
    const membersToDisplay = members.slice(0, 2);
    const membersNotToDisplay = members.slice(2);

    return <>
        {membersToDisplay.map((member) => {
            return <span className="font-medium text-sm" key={member.userId}>{member.nickname}, </span>
        })}
        <span className="font-medium text-sm" >
            {membersNotToDisplay.length > 0 && `+ ${membersNotToDisplay.length}`}
        </span>
    </>
}
const Channel = ({ currentlyJoinedChannel, children, HandleBackBtn, handleLeaveChannel, channelRef,userNameInputValue,handleCreateChannelBot}) => {
    let params = useParams()
    const closeChannel = () => {
        document.querySelector('.channel').style.zIndex = '1';
    }
    
    if (currentlyJoinedChannel) {
        return <div className="channel" useRef={channelRef} style={{zIndex:'1'}}>
                    <div>
                        <div className='flex items-center justify-between px-2'>
                          
                        </div>
                    </div>
                
                    <div className='flexStrom'>
                        
                        {children}
                    </div>
        </div>;
    }
    return <div className="channel flex items-start justify-start" style={{zIndex:'1'}}>
        { params.id || params.slug ? <Outlet context={[handleCreateChannelBot,userNameInputValue]}/> : <Categorylist userNameInputValue={userNameInputValue} createChannel={handleCreateChannelBot}/> }
    </div>;
}

const ChannelHeader = ({ children }) => {
    return <div className="channel-header flex justify-between items-center drop-shadow-sm">{children}</div>;
}

const MembersList = ({ channel, handleMemberInvite,handleLeaveChannel,handlePopup,handleUpdateChannel,channelDataUpdate,currentlyUpdatingChannelUpdate, dataHandleShowListItem, sidebarSlider}) => {
    const [channelData, setChannelData] = useState()
    const [oldchannelData, setOldChannelData] = useState([])
    const [selectedfile, SetSelectedFile] = useState([]);
    const [deleteState, updateDeleteState] = useState();
    const [enabled, setEnabled] = useState(false);
    const [stateUpdate,setStateUpdate] = useState(false);
    const [groupDescription, setGroupDescription] = useState('');
    const [fBaseGroupDescription, setFBaseGroupDescription] = useState(null);
    const [descriptionPopup, setDescriptionPopup] = useState(false);
    const [descriptionSave, setDescriptionSave] = useState(false);
    const [loadfiles, setLoadFiles] = useState(false);
    const [fileLink, setFileLink] = useState(null);


const fetchPost = async () => {
  try {
    const docRef = doc(db, "Storms", channel.url);
    const docSnap = await getDoc(docRef);
    setFBaseGroupDescription(docSnap.data().prompt);

    await  getDocs(collection(docRef, "Files"))
            .then((querySnapshot)=>{               
                const newData = querySnapshot.docs
                    .map((doc) => ({...doc.data(), id:doc.id }));
                    setFileLink(newData);                
            })
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

    useEffect(() => {
        fetchPost();
    }, []);

    useEffect(()=>{
        if(deleteState == true){
            handleUpdateChannel();
        }
    },[deleteState])

    useEffect(()=>{
        if(stateUpdate == true){
            handleUpdateChannel();
            setStateUpdate(false)
        }
    },[stateUpdate])
    
    useEffect(()=>{
        if(stateUpdate == true){
            let data = channel.data && JSON.parse(channel.data) != null && (JSON.parse(channel.data).prompt || JSON.parse(channel.data).files) ? JSON.parse(channel.data) : {"prompt":'',"files":[]}
        
        if(channelData.files && channelData.files.length >0){
            data.files.push(channelData.files)
            
        }else{
            data.files = channelData.files ? channelData.files : [];
        }
        if(channelData.prompt){
            data.prompt = channelData.prompt;
            
        }
        channelDataUpdate(data)
        
        }
    },[channelData])

    useEffect(()=>{
        if(channel != null){
            currentlyUpdatingChannelUpdate(channel)
        }
    },[])
   
    useEffect(()=>{
        if(channel != null){
            if(channel.data){
                const data = JSON.parse(channel.data);
                setChannelData(data && (data.files || data.prompt ) ? data : {"prompt":'',"files":[]})
                setOldChannelData(data ? data.files : [])
                setGroupDescription(data ? data.prompt : '');
            }else{
                setChannelData({"prompt":'',"files":[]})
            }
        }
        SetSelectedFile([])
     },[channel])

    const groupDescriptionHandler =()=>{
        setDescriptionPopup(!descriptionPopup);
    }

    const metaSaver = async (e)=>{
        setDescriptionSave(true);
        const data = {
            prompt: fBaseGroupDescription, // Update an existing item with a new value.
        };
        const updatedDescription = doc(db,'Storms', channel.url)
        updateDoc(updatedDescription,{
        prompt:fBaseGroupDescription,
        } ).then(response => {
        
        }).catch(error =>{
        console.log(error.message)
        })
        
        setStateUpdate(true)
        setTimeout(() => {
            setDescriptionSave(false);
            groupDescriptionHandler();
        }, 2000);  
    
    }
    

    const filesizes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }



    const submitHadler = async() => {

    for( let fileLength=0 ; fileLength < selectedfile.length ; fileLength++ ){
        axios({
            url:`${SENDBIRD_INFO.mediaUrl}/media/channel/files`,
            method: 'post',
            data: {
                'attachment': selectedfile[fileLength].fillePath,
                'is_save':enabled,
                'channel_url':channel.url,
            },
            headers: {
                'Content-Type':'multipart/form-data',
            },
        })
        //refresh component here and remove then
        .then((res) => res)
        .then((response) => {
            Notiflix.Loading.remove();
            setLoadFiles(false);
            setStateUpdate(true);

            fetchPost();


        })
        .catch((err) => {
            Notiflix.Loading.remove();
            console.log(err.message+"file-error");
            setLoadFiles(false);
         });
        
        }   
    }
    const InputChange2 = (e) => {
        setLoadFiles(true);
        let images = [];
        for (let i = 0; i < e.target.files.length; i++) {
            images.push((e.target.files[i]));
            let reader = new FileReader();

            let file = e.target.files[i];

            let extension = file.name.split('.').pop().toLowerCase();
            
            if(extension =='pdf'){
                reader.onloadend = () => {
                    SetSelectedFile((preValue) => {
                        return [
                            ...preValue,
                            {
                                id: shortid.generate(),
                                filename: e.target.files[i].name,
                                filetype: e.target.files[i].type,
                                fileimage: reader.result,
                                fillePath: e.target.files[i],
                                filesize: filesizes(e.target.files[i].size)
                            }
                        ]
                    });
                    setLoadFiles(false);
                    e.target.value = null;
                }
                if (e.target.files[i]) {
                    reader.readAsDataURL(file);
                }
              }else{
                Notiflix.Report.warning(
                    'Please upload pdf file',
                    "",
                    "okay",
                );
                setLoadFiles(false);
                }
   
        }
      
    }

    const DeleteSelectFile = (id) => {
        if(window.confirm("Are you sure you want to delete this file?")){
            const result = selectedfile.filter((data) => data.id !== id);
            SetSelectedFile(result);
        }else{
            // alert('No');
        }
        
    }
    const DeleteFileFromServer = async (id,filename) => {
        Notiflix.Confirm.show(
            "Confirm",
            "Are you sure you want to delete this file?",
            "Yes",
            "No",
            () => {
             axios({
                    url:process.env.REACT_APP_MEDIA_URL+'/delete_file',
                    method: 'post',
                    data: {
                        "filename": filename,
                        "file_id":id,
                        "channel_url": channel.url
                    },
                  
                })
                .then((res) => res)
                .then((response) => {
                    updateDeleteState(true)
                    Notiflix.Notify.success('File deleted!');

                    fetchPost();
                })
                .catch((err) => {
                    console.log(err.message+"file-error");
                    
                 }); 
             
            }
            
        )
    }
 
    
    if (channel) {
        return <div className="members-list h-full relative transition-opacity">
            <span className="text-sm font-normal block absolute right-4 top-6" onClick={()=>dataHandleShowListItem("text")}>{sidebarSlider? <a className='cursor-pointer'><img className="" src='/assets/images/cancel.svg' /></a> : <a className='cursor-pointer'><img className="" src='/assets/images/editIcon.svg' /></a>}</span>
            <div className="text-md font-bold mb-2 flex justify-between mt-10 items-center">
                    <h3 className='font-bold text-md mr-2'>Storm Description </h3>
                    <a className='cursor-pointer w-5' onClick={groupDescriptionHandler}> <img src="/assets/images/editIcon_dis.svg" alt="add-people" /></a>
                </div>
                
                {!descriptionPopup ? (
                 fBaseGroupDescription!= ' ' && fBaseGroupDescription!= '' ? 
                    <div>
                        <p>{fBaseGroupDescription}</p>
                    </div>
                    :
                    <div>
                        <p>Personalise your experience by sharing a description of what you will be discussing with this bot, including any information you would like it to know and remember when generating answers.</p>
                    </div>
                    ) : (
                    <div className='mb-4 break-all'>
                <textarea rows="4" style={{"width": "100%"}} className="p-4" onChange={(e) => setFBaseGroupDescription(e.target.value)} value={fBaseGroupDescription}>{fBaseGroupDescription}</textarea>
                <div className="kb-buttons-box text-right mt-2">
                    <button type="button" className={`send-message-button mb-2 bg-${!descriptionSave ? '[#2C2D98]' : 'red-700'} rounded-md py-1 text-white cursor-pointer w-auto px-3 form-submit `} onClick={metaSaver} disabled={descriptionSave}>{descriptionSave ? 'Saved' : 'Save'}</button>
                </div>
            
            </div>)}
           
            
            <div className='files-ct'>
                <div className="text-md font-bold mt-2 mb-4 flex justify-between items-center">
                    <h3 className='font-bold text-md mr-2'>Files ({fileLink && fileLink.length})</h3>
                    <div className="file-upload-box cursor-pointer ml-2"><input type="file" id="fileupload" className="file-upload-input w-auto cursor-pointer" accept="application/pdf" multiple="" onChange={InputChange2}/><span className="file-link font-semibold text-[#2C2D98] flex items-center cursor-pointer px-0" htmlFor="fileupload"><img src="/assets/images/select-link-new.svg" alt="" width="30" height="30" /></span></div>
                </div>
                <ul className='mb-5'>
                        {fileLink && fileLink.map((item)=>(
                            <li key={item.fileurl} className={`${item.fileurl!=""? 'bg-white': 'bg-cyan-100'} file-item  p-2 flex items-center justify-between my-2`}>
                                    <div className='flex'>
                                            <img src="/assets/images/bi_file-pdf.svg" alt="file-icon" width={18} height={15} /> 
                                            {item.fileurl!=""?
                                                <a href={item.fileurl} target="_blank" className='file-name text-sm font-semibold text-[#252525] ml-2 leading-4 break-normal'>{item.filename}</a>:
                                                <span className='file-name text-sm font-semibold text-[#252525] ml-2 leading-4 break-normal'>{item.filename}</span>}
                                    </div>
                                    <div className='flex'>
                                       {item.fileurl!="" && <a className='cursor-pointer'  ><img src="/assets/images/delete-icon2.svg" alt="delete-icon" className='delete-file' onClick={() => DeleteFileFromServer(item.id,item.filename_aws)}/></a>}
                                    </div>
                            </li>
                        ))}
                            
                    </ul>
                <ul className="files-list mt-3 mb-5">
                     {loadfiles ? 
                        <div className="loader-ct absolute">
                            <img src='/assets/images/loader3.gif' width={20} height={20}></img>
                        </div>:null}
                    {
                        selectedfile.map((data, index) => {
                            const { id, filename, filetype, fileimage, datetime, filesize, fileurl } = data;
                            return (
                                    <li className="file-item bg-white p-2 flex items-center justify-between my-2" key={id}>
                                        <div className='flex'>
                                            {
                                                filename.match(/.(pdf)$/i) ?
                                                <img src="/assets/images/bi_file-pdf.svg" alt="file-icon" width={18} height={15} /> : filename.match(/.(word)$/i) ? 
                                                <img src="/assets/images/ri_file-word-2-line.svg" alt="file-icon" width={18} height={15} />:''
                                            }
                                            <a href={fileurl} target="_blank" className='file-name text-sm font-semibold text-[#252525] ml-2 leading-4 break-normal'>{filename}</a>
                                        </div>
                                        <div className='flex'>
                                            <a className='cursor-pointer' onClick={() => DeleteSelectFile(id)} ><img src="/assets/images/delete-icon2.svg" alt="delete-icon" className='delete-file'/></a>
                                        </div>
                                    </li>
                            )
                        })
                    }
                </ul>
              
                {selectedfile.length != 0 && (
                    <>
                        <div className="kb-buttons-box">
                            <button type="button" className="send-message-button mb-2 bg-[#2C2D98] rounded-md py-1 text-white cursor-pointer w-auto px-3 form-submit " onClick={submitHadler}>Upload file</button>
                        </div>
                    </>
                
                )}
            </div>

            <div className="text-md font-bold mt-2 mb-4 flex justify-between"> 
                <span>Participants ({channel.members.length}) </span>
                <a className='cursor-pointer' onClick={handlePopup}> <img src="/assets/images/people-add-outline.svg" alt="add-people" /></a>
            </div>
           <div className="mb-3">
            {channel.members.map((member) =>
                     <div className={`member-item bg-white p-2 ${member.connectionStatus=="online" || member.userId.includes('bot_') || member.userId.includes('Brainstorm') ? 'online' : 'offline'}`}key={member.userId}>
                        {member.nickname}
                     </div>
                )}
           </div>
            
             <button className="leave-channel bg-red-700 text-white w-full ml-0 rounded-md text-sm" onClick={handleLeaveChannel}>Exit Storm</button>
        </div>;
    } else {
        return null;
    }
}

                  
const MessagesList = ({ messages, handleDeleteMessage, updateMessage,currentlyJoinedChannel,channelRef,dataHandleShowListItem, sidebarSlider,HandleBackBtn}) => {
    const closeChannel = () => {
        document.querySelector('.channel').style.zIndex = '0';
        HandleBackBtn();
    }
       return <div className="message-list" ref={channelRef}>
        <ChannelHeader >

            <div className='text-lg flex items-center max-w-[90%]'>
            <span className='block'><img src="/assets/images/leftArrow.svg" alt="left-arrow-icon" onClick={HandleBackBtn}/></span>
                {currentlyJoinedChannel.name}
            </div>
            <div className='flex items-center header-right-icons'>
            <span className="text-sm font-normal pr-4" onClick={()=>{window.Usersnap.logEvent('feedback_submit')}}><a className='cursor-pointer'><img className="" src='/assets/images/ic_outline-feedback.svg' /></a></span>

            <span className="text-sm font-normal" onClick={()=>dataHandleShowListItem("text")}>{sidebarSlider? null : <a className='cursor-pointer'><img className="" src='/assets/images/editIcon.svg' /></a>}</span>

            </div> 
             </ChannelHeader> 
        {messages.map(message => {
            if (!message.sender) return null;
            const messageSentByYou = message.sender.userId === sb.currentUser.userId;
            return (
                <div key={message.messageId} className={`message-item ${messageSentByYou ? 'message-from-you' : ''}`}>
                    <Message
                        message={message}
                        handleDeleteMessage={handleDeleteMessage}
                        updateMessage={updateMessage}
                        messageSentByYou={messageSentByYou} />
                    <ProfileImage user={message.sender} />
                </div>
            );
        })}
    </div>
}

const Message = ({ message, updateMessage, handleDeleteMessage, messageSentByYou }) => {
    if (message.url && message.type == 'image/png') {
        return (
            <div className={`message  ${messageSentByYou ? 'message-from-you' : ''}`}>
                <div className="message-user-info">
                    <div className="message-sender-name">{message.sender.nickname}{' '}</div>
                    <div>{timestampToTime(message.createdAt)}</div>
                </div>
                <img src={message.url} />
            </div >);
    }else if(message.url && message.type != 'image/png'){
        return (
            <div className={`message  ${messageSentByYou ? 'message-from-you' : ''}`}>
                <div className="message-user-info">
                    <div className="message-sender-name">{message.sender.nickname}{' '}</div>
                    <div>{timestampToTime(message.createdAt)}</div>
                </div>
                <a href={message.url}>File - {message.name}</a>
            </div >);
    }
    const messageSentByCurrentUser = message.sender.userId === sb.currentUser.userId;

    function LinkRenderer(props) {
        return <a href={props.href} target="_blank">{props.children}</a>
      }

    return (        
        <div className={`message  ${messageSentByYou ? 'message-from-you' : ''}`}>
            <div className="message-info">
                <div className="message-user-info">
                    <div className="message-sender-name">{message.sender.nickname}{' '}</div>
                    <div className='text-gray-500'> {timestampToTime(message.createdAt)}</div>
                </div>
                {messageSentByCurrentUser &&
                    <div className='hidden sm:block'>
                        <button className="control-button" onClick={() => updateMessage(message)}><img className="message-icon" src='/icon_edit.png' /></button>
                        <button className="control-button" onClick={() => handleDeleteMessage(message)}><img className="message-icon" src='/icon_delete.png' /></button>
                    </div>}
            </div>
            <ReactMarkdown components={{ a: LinkRenderer}} children={message.message}  remarkPlugins={[gfm,remarkMath,remarkRehype]} rehypePlugins={[rehypeRaw]} />
             
        </div>
    );
}

const ProfileImage = ({ user }) => {
    if (user.plainProfileUrl) {
        return <img className="profile-image" src={user.plainProfileUrl} />
    } else {
        return <div className="profile-image-fallback">{user.nickname.charAt(0)}</div>;
    }
}

const MessageInput = ({ value, onChange, sendMessage, onFileInputChange, suggestion }) => {
    const messagesEndRef = useRef(null)
    const notMatchingRegex = /($a)/;

        function enterStormMsg(e) {
            if((e.which === 13 && !e.shiftKey)) {
                e.preventDefault();
                document.getElementById("send_message").click();
            }
        };
          
    return (
        <div className="message-input w-full">
            <div className='w-full flex px-2' ref={messagesEndRef}>

                <MentionsInput value={value}  onChange={onChange} id='storm_msg' onKeyDown={enterStormMsg} placeholder="" className='chat-input-box shadow-sm relative block rounded-md  border-0 py-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-md w-full sm:w-1/2 lg:w-[100%] h-12 my-2' >
                    <Mention
                        markup="@__display__"
                        regex={notMatchingRegex}
                        trigger="@"
                        appendSpaceOnAdd="true"
                        data={suggestion}
                        className='suggestion-box p-3 absolute z-10 border border-1 bg-[#fff]'
                    />
                </MentionsInput>
              
                <div className="message-input-buttons flex sm:w-1/2 lg:w-[20%] align-items-center">
                    <input
                        id="upload"
                        className="file-upload-button"
                        accept="application/pdf"
                        type='file'
                        hidden={true}
                        onChange={onFileInputChange}
                        onClick={() => { }}
                    />
                    <label className="file-upload-label my-2 mx-2 flex justify-center flex-col" htmlFor="upload" ><img src="/assets/images/select-link.svg" alt="" width={20} height={20}/></label>
                    <button type='button' value="Send" id='send_message' disabled={!value.trim().length} className="send-message-button my-2 bg-[#2C2D98] rounded-lg py-1 text-white cursor-pointer w-auto px-3 sm:w-[70%]" onClick={sendMessage} >
                        <span className="hidden sm:block">Send</span>
                        <span className="block sm:hidden">
                            <img src="/assets/images/sendIcon.svg" width={20} height={20}/>
                        </span>
                    </button>
                </div>
            </div>
        </div>
    );
}


// Invite new user to chanel first
const InviteUserch =({showpopState,handlePopup,handleSendIvite,userSucessMsg}) =>{
    const [userName, setUseser] = useState("");
    const [useremailId,setUseremailId] = useState("")

    const validationSchema = Yup.object({
        username: Yup.string().required('*Name is required field').required(),
        useremailId: Yup.string().required("*Email is required field").email(),
      });
      const formOptions = { resolver: yupResolver(validationSchema) };
      const { register, handleSubmit, formState } = useForm(formOptions);
      const { errors } = formState;


      const onSubmit = async (e) => {
        handleSendIvite({userName,useremailId})
      }
    useEffect(()=>{
        setUseser("")
        setUseremailId("")
    },[showpopState])
    return(
        <>
        {!userSucessMsg?
        <div className={`overlay ${showpopState?'hidden':'flex'}`}>
             <div className="overlay-content relative rounded-md">
             <span onClick={handlePopup} className="close-icon text-lg font-bold px-2 py-0 absolute right-1 top-1 rounded-full align-middle cursor-pointer">✕</span>
            
             <>
               
                <h4 className="text-lg font-bold mb-3">Invite User to Storm</h4>
                    <div className='mb-3 '>
                        <label htmlFor="userName" className="sm:text-sm opacity-70 font-bold">User Name</label>
                        <input id="userName" required name="userName" type="text" value={userName} {...register('username')}  onChange={(e) => setUseser(e.target.value)} className="form-appearance shadow-sm relative block w-full rounded-md border-0 py-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6" placeholder="Enter User Name"/>
                        <span className='text-red-500 sm:text-xs'>{errors.username?.message}</span>
                    </div>
                    <div className='mb-3 '>
                        <label htmlFor="useremail" required className="sm:text-sm opacity-70 font-bold">User Email Id</label>
                        <input id="useremail" name="useremail" type="email" value={useremailId} {...register('useremailId')}  onChange={(e) => setUseremailId(e.target.value)}  className="form-appearance shadow-sm relative block w-full rounded-md border-0 py-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6" placeholder="Enter User Email Id"/>
                        <span className='text-red-500 sm:text-xs'>{errors.useremailId?.message}</span>
                    </div>
                    <button onClick={handleSubmit(onSubmit)}   className='group relative flex w-full justify-center rounded-md bg-indigo-600 py-2 px-3 text-md font-semibold text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'>Send Invite</button>
                    </>
                   
             </div>
        </div>
         :
         <>
         {Notiflix.Report.success(
             'Success',
             `Invite successfully send to ${useremailId}`,
             'Okay',
            ()=>{
                handlePopup()
            }
         )}
         
         </>
         }
        </>
        
    )
}


const MembersSelect = ({
    applicationUsers,
    groupChannelMembers,
    currentlyJoinedChannel,
    addToChannelMembersList,
    handleCreateChannel,
    handleJoinChannel,
    handleUpdateChannelMembersList,
    handleCreateStormPopup,
    showCreatePopup,
    createStormSuccessMsg
}) => {
    const [cName, setCname] = useState("");
    const [cDiscription,setCdiscription] = useState("")
    const [showpop,setShowPop] = useState(null);
    const onClick = () => setShowPop(true);

    const [selectedfile, SetSelectedFile] = useState([]);
    const [Files, SetFiles] = useState([]);
    const [enabled, setEnabled] = useState(false);
    const [errors, setError] = useState("")

    const handleSubmit = async (e) => {
        if (cName.length == 0) {
            setError("Storm name is required");
          }else if(cName.length >= 50){
            setError("You can't use more than 50 characters.");
          } else {
            setError("")
            handleCreateChannel(cName,data,selectedfile,handleCreateStormPopup)
          }
    }

    

    const filesizes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    const [loadfiles, setLoadFiles] = useState(false);


    const DeleteSelectFile = (id) => {
        if(window.confirm("Are you sure you want to delete this Image?")){
            const result = selectedfile.filter((data) => data.id !== id);
            SetSelectedFile(result);
        }else{
            // alert('No');
        }
        
    }

    const FileUploadSubmit = async (e) => {
        e.preventDefault();

        // form reset on submit 
        e.target.reset();
        if (selectedfile.length > 0) {
            for (let index = 0; index < selectedfile.length; index++) {
                SetFiles((preValue)=>{
                    return[
                        ...preValue,
                        selectedfile[index]
                    ]   
                })
            }
            SetSelectedFile([]);
        } else {
            alert('Please select file')
        }
    }



    useEffect(()=>{
        setCname("")
        setCdiscription("")
        setShowPop(false)
    },[handleCreateChannel])
    
    const data = {
        'prompt': cDiscription,
    };
    if (applicationUsers.length > 0) {
        
        return <>
        {!createStormSuccessMsg ?
        <div className={`overlay ${showCreatePopup ? 'hidden':'flex'}`}>
            <div className="overlay-content relative fileupload-view rounded-md">
            <span onClick={handleCreateStormPopup} className="close-icon text-lg font-bold absolute right-1 top-1 rounded-full align-middle cursor-pointer">✕</span>
            {!currentlyJoinedChannel?
                (
                    <>
                    <h4 className="text-lg font-bold mb-3">Add Your Storm</h4>
                    <div className='mb-3 '>
                        <label htmlFor="channelName" className="sm:text-sm opacity-70 font-bold">Name</label>
                        <input id="channelName"  name="channelName" value={cName} onChange={(e) => setCname(e.target.value)}  type="text"  className="form-appearance shadow-sm relative block w-full rounded-md border-0 py-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6" placeholder="Enter Storm Name"/>
                        <span className='text-red-500 sm:text-xs'>{errors}</span>
                    </div>
                    <div className='mb-3 '>
                        <label htmlFor="channelDiscription" className="sm:text-sm opacity-70 font-bold">Bot Instructions</label>
                        <textarea
                        placeholder="Give your bot instructions to customise it for your use case. This is called a prompt, it’s like telling the bot what it’s supposed to be good at and how it is expected to help you. The better your prompt, the better the bot. E.g. You are a financial advisor with expertise in managing accounts for small businesses. You will be given financial documents and will be expected to look up and compute information based on questions you are asked. You will provide an explanation for the answers you share that will help me verify your approach"
                        rows={6}
                        cols={40}
                        className="h-[300px] sm:h-auto form-appearance px-2 shadow-sm relative block w-full rounded-md border-0 py-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6" 
                        id="channelDiscription" name="channelDiscription" value={cDiscription} onChange={(e) => setCdiscription(e.target.value)}
                    />
                    </div>                    
                    {Files.length > 0 ?
                        <div className="kb-attach-box">
                            <hr />
                            {
                                Files.map((data, index) => {
                                    const { id, filename, filetype, fileimage, datetime, filesize, fileurl } = data;
                                    return (
                                        <div className="file-atc-box" key={index}>
                                            {
                                                filename.match(/.(pdf)$/i) ?
                                                    <div className="file-image"> <img src="/assets/images/bi_file-pdf.svg" alt="" /></div> : filename.match(/.(word)$/i) ?
                                                    <div className="file-image"><img src="/assets/images/ri_file-word-2-line.svg" alt="" /></div> :''
                                            }
                                            <div className="file-detail">
                                                <a href={fileurl} target="_blank">{filename}</a>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        : ''}
                    </>
                )
            :null}


                <button type='submit' className='group relative flex w-full justify-center rounded-md bg-indigo-600 py-2 px-3 text-md font-semibold text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600' onClick={() => {
                    if (currentlyJoinedChannel) {
                        handleUpdateChannelMembersList();
                    } else {
                        handleSubmit()
                    }
                }}>{currentlyJoinedChannel ? 'Submit' : 'Create A Storm'}</button>
                {currentlyJoinedChannel?
                    applicationUsers.map((user) => {
                    const userSelected = groupChannelMembers.some((member) => member === user.userId);
                    return <div
                        key={user.userId}
                        className={`member-item ${userSelected ? 'member-selected' : ''}`}
                        onClick={() => addToChannelMembersList(user.userId)}>
                        <ProfileImage user={user} />
                        <div className="member-item-name">{user.nickname}</div>
                    </div>
                    })
                :null}
            </div>
        </div>:null
         } 
        </>
    }
    return null;
}

const CreateUserForm = ({
    setupUser,
    settingUpUser,
    userNameInputValue,
    userIdInputValue,
    onUserNameInputChange,
    onUserIdInputChange
}) => {
    if (settingUpUser) {
        return <div className="overlay">
            <div className="overlay-content" onKeyDown={(event) => handleEnterPress(event, setupUser)}>
                <div>User ID</div>
                <input
                    onChange={onUserIdInputChange}
                    className="form-input"
                    type="text" value={userIdInputValue}
                />
                <div>User Nickname</div>
                <input
                    onChange={onUserNameInputChange}
                    className="form-input"
                    type="text" value={userNameInputValue}
                />
                <button
                    className="user-submit-button"
                    onClick={setupUser}
                >
                    Connect
                </button>
            </div>
        </div>
    } else {
        return null;
    }
}

// Helpful functions that call Sendbird
const loadChannels = async (channelHandlers) => {
    const groupChannelFilter = new GroupChannelFilter();
    groupChannelFilter.includeEmpty = true;

    const collection = sb.groupChannel.createGroupChannelCollection({
        filter: groupChannelFilter,
        order: GroupChannelListOrder.LATEST_LAST_MESSAGE,
    });

    collection.setGroupChannelCollectionHandler(channelHandlers);

    const channels = await collection.loadMore();
    return [channels, null];
}

const loadMessages = (channel, messageHandlers, onCacheResult, onApiResult) => {
    const messageFilter = new MessageFilter();

    const collection = channel.createMessageCollection({
        filter: messageFilter,
        startingPoint: Date.now(),
        limit: 100
    });

    collection.setMessageCollectionHandler(messageHandlers);
    collection
        .initialize(MessageCollectionInitPolicy.CACHE_AND_REPLACE_BY_API)
        .onCacheResult(onCacheResult)
        .onApiResult(onApiResult);
    return collection;
}

const inviteUsersToChannel = async (channel, userIds) => {
    await channel.inviteWithUserIds(userIds);
}

const createChannel = async (channelName, userIdsToInvite, createChannel, dataPdffile, channelImg) => {
    try {
        const groupChannelParams = {};
        var imgURL ;
        imgURL = channelImg

        groupChannelParams.invitedUserIds = userIdsToInvite;
        groupChannelParams.name = channelName;
        groupChannelParams.coverUrl = imgURL;
        const groupChannel = await sb.groupChannel.createChannel(groupChannelParams);
        axios({
            url:`${SENDBIRD_INFO.backendUrl}/create_channel`,
            method: 'post',
            data: {
                'prompt':createChannel.prompt ? createChannel.prompt : " ",
                'channel_url':groupChannel.url,
                'name':groupChannel.name,
                'bot':userIdsToInvite[0],
                'coverUrl':groupChannelParams.coverUrl
            },
            headers: {
                'Content-Type':'multipart/form-data',
            },
        })
        return [groupChannel, null];
    } catch (error) {
        return [null, error];
    }
}

const deleteChannel = async (channelUrl) => {
    try {
        const channel = await sb.groupChannel.getChannel(channelUrl);
        await channel.delete();
        return [channel, null];
    } catch (error) {
        return [null, error];
    }
}

const deleteMessage = async (currentlyJoinedChannel, messageToDelete) => {
    await currentlyJoinedChannel.deleteMessage(messageToDelete);  
}

const getAllApplicationUsers = async () => {
    try {
        const userQuery = sb.createApplicationUserListQuery({ limit: 100 });
        const users = await userQuery.next();
        return [users, null];
    } catch (error) {
        return [null, error];
    }
}

const updateChannel = async (currentlyUpdatingChannel, channelDataValue) => {
    try {
        const channel = await sb.groupChannel.getChannel(currentlyUpdatingChannel.url);
        const openChannelParams = {};
        openChannelParams.data = JSON.stringify(channelDataValue);
        const updatedChannel = await channel.updateChannel(openChannelParams);
        return [updatedChannel, null];
    } catch (error) {
        return [null, error];
    }
}

const loadOperators = async (channel) => {
    try {
        const query = channel.createOperatorListQuery();
        const operators = await query.next();
        return [operators, null];
    } catch (error) {
        return [null, error];
    }
}

const handleLoadOperators = async (channel) => {
    const [operators, error] = await loadOperators(channel);
    if (error) {
        return error;
    }
    return operators
}


export default MainWindow;